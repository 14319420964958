<!-- 打样卖方订单 -->
<template>
  <div class="zt-page-content" ref="load">
    <div style="min-width:1200px;height:100%;overflow-y:hidden;">
      <div class="zt-block flex flex-v" style="height:calc(100% - 40px);padding:20px 20px 0 20px;">
        
        <div style="overflow-y:auto;" class="table flex-1">
          <div class="tbody">
            <div v-for="(item,idx) in list" :key="idx" class="order-block">
              <div class="ob-header">
                  <div class="order-head">
                    <el-row>
                        <el-col :span="3">标题</el-col>
                        <el-col :span="3">交易日期</el-col>
                        <el-col :span="3">订单编号</el-col>
                        <el-col :span="3">订单状态</el-col>
                        <el-col :span="4">对方打样公司</el-col>
                        <el-col :span="3">期望验收日期</el-col>
                        <el-col :span="5">实际出价</el-col>
                    </el-row>
                </div>
                <div class="order-head1">
                    <el-row>
                        <el-col :span="3">
                            <a @click="viewDetail(item)" class="ellipsis">{{item.requirement.title}}</a>
                        </el-col>
                        <el-col :span="3">{{item.createdAt | moment("YYYY/MM/DD")}}</el-col>
                        <el-col :span="3">{{item.code}}</el-col>
                        <el-col :span="3">
                          <div v-if="item.tradeOrder && item.tradeOrder.state<2">待对方签约</div>
                            <div v-if="item.tradeOrder && item.tradeOrder.state>2 && item.tradeOrder.state<4">待我方签约</div>
                            <div v-if="item.tradeOrder && item.tradeOrder.state==4">待对方付款</div>
                            <div v-if="item.tradeOrder && item.tradeOrder.state==5">待我方收款</div>
                            <div v-if="item.tradeOrder && item.tradeOrder.state==6">交易完成</div>
                        </el-col>
                        <el-col :span="4">{{item.sellerComp.name}}</el-col>
                        <el-col :span="3">{{item.requirement.dayang.checkDate | moment("YYYY/MM/DD")}}</el-col>
                        <el-col style="font-size:12px;" v-if="item.tradeOrder" :span="5">
                          <span style="color:#EE1818;">￥{{item.tradeOrder.ar | priceFilt}}</span> 
                          已收款
                          <span style="color:#EE1818;">￥{{item.tradeOrder.confirmAmount | priceFilt}}</span>
                        </el-col>
                    </el-row>
                </div>
              </div>
              <div class="order-body">
                <el-row v-for="(item1,idx1) in item.requirement.dayang.items" :key="idx1">
                  <el-col :span="5">
                    <div style="width:60px;height:60px;margin:0 auto;">
                        <div v-if="item1.renderImgs && item1.renderImgs.length>0" class="bg_img"
                        :style="{backgroundImage: 'url(' + imgUrl+item1.renderImgs[0]+'!width_250px' + ')'}"></div>
                    </div>
                  </el-col>
                  <el-col :span="5">
                      <div style="font-size:16px;color:#808080;" class="flex">
                          <div style="color:#B3B3B3;margin-right:16px;">类目</div>
                          <div>{{item1.cat.name}}</div>
                      </div>
                  </el-col>
                  <el-col :span="5">
                      <div style="font-size:16px;color:#808080;" class="flex">
                            <div style="color:#B3B3B3;margin-right:16px;">需求量</div>
                            <div>{{item1.count}}个</div>
                        </div>
                  </el-col>
                  <el-col :span="4"></el-col>
                  <el-col :span="5"></el-col>
                </el-row>
              </div>
              <div class="order-foot">
                <SignPlan v-if="hackReset" :item="item" :planType="'dy_order'" @refresh="refreshItems"></SignPlan>

              </div>
            </div>
          </div>
        </div>
        <div style="padding:15px 0;text-align:center;">
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page.sync="queryOpt.page.pageNumber"
            :page-size="queryOpt.page.pageCount"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {} from "@/service/company";
import { IMG_URL_PRE } from "@/config";
import { dayangSellerList } from '@/service/want';
import { getPayId } from "@/service/pay";
import SignPlan from '@/components/tradeOrder/Seller.vue';

export default {
  components: { SignPlan },
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      popVisible: false,
      popTitle: "",
      queryOpt: {
        page: {
          pageNumber: 1,
          pageCount: 15
        },
        where: {
          buyerCompIds: null
        },
      },
      total: 0,
      list: [],
      hackReset:true,//刷新子组件
    };
  },
  watch: {
    queryOpt: {
      handler() {
        this.debounceRefreshItems();
      },
      deep: true
    }
  },
  created() {
    this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
  },
  mounted() {
    this.refreshItems();
  },
  methods: {
    goto(path) {
      this.$router.push(path);
    },
    viewDetail(item) {
        this.$router.push('/want/joinWant/pipe/'+item.requirementId);
    },
    refreshItems() {
      this.list = [];
      dayangSellerList(this.queryOpt).then(rst => {
          if (rst.rows && rst.rows.length) {
            this.total = rst.count;
            this.list = rst.rows;
            // 签约状态
            if (this.list && this.list.length>0) {
                this.list.forEach((e,idx)=> {
                  if (e.tradeOrder) {
                    e.trans_stat = e.tradeOrder.state;
                    e.contract_no = e.tradeOrder.contract_no;
                  }
                });
            }
          }
        }).catch(err => {
          console.error(err);
          this.$message.error(err.message);
        });
        this.hackReset = false;
        this.$nextTick(() => {
            this.hackReset = true;
        })
    },
    // 确认收款 ,  查看订单记录
    qr_order(item) {
      getPayId('pur_order',item.id).then(rst => {
        console.log("getPayId", rst);
        this.$router.push({
            path:"/pay/payInfo",
            query:{
                order_code:rst.id,
                order_id:item.id,
            }
        });
      }).catch(err => {
        console.log(err);
        this.$message.error(err.message);
      });
      
    },

  }
};
</script>
<style>
</style>
<style scoped src="../style.css"></style>
<style scoped lang="less">
.block {
  padding: 13px 32px 63px;
  color: #808080;
}
.table {
  .tbody {
    .order-block {
      margin-top: 16px;
      box-shadow:0px 3px 6px rgba(0,0,0,0.16);
      .ob-header {
          padding: 0 30px;
          background:rgba(245,245,245,1);
          box-shadow:0px 1px 3px rgba(0,0,0,0.16);
          .order-head {
            border-bottom: 2px solid #e4e4e4;
            font-size: 16px;
            color: #808080;
            font-weight: bold;
            .el-col {
                height: 40px;
                text-align: center;
                line-height: 40px;
            }
        }
        .order-head1 {
            font-size: 14px;
            color: #808080;
            .el-col {
                height: 35px;
                text-align: center;
                line-height: 35px;
            }
        }
      }
      .order-body {
        padding: 16px 0;
        border:1px solid #e6e6e6;
        border-bottom: 1px dashed #e6e6e6;
        .el-col {
          height: 60px;
          line-height: 60px;
          text-align: center;
          span {
            color: #ee1818;
          }
        }
      }
      .order-foot {
        padding: 20px;
        border:1px solid #e6e6e6;
        border-top: 0;
      }
    }
    .order-block:first-child {
      margin-top: 0;
    }
  }
}
</style>
<style scoped>
    .item-plan {
        margin-top: 10px;
    }
    .item-plan>>> .el-step__icon.is-icon,.el-step__icon {
        width: unset;
    }
</style>
